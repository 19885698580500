import { makeRequest } from './index.js';

const endpoints = {
  getProperties: async params =>
    makeRequest(
      'GET',
      `singgahsini/api/owner-expenditure/property-expense/property-suggestion`,
      params
    ),
  getPropertyExpensesOptions: async () =>
    makeRequest(
      'GET',
      'singgahsini/api/owner-expenditure/property-expense/create'
    ),
  getPropertyExpensesVendors: async () =>
    makeRequest(
      'GET',
      'singgahsini/api/owner-expenditure/property-expense/vendor'
    ),
  postExpenditureAttachment: async data =>
    makeRequest(
      'POST',
      'singgahsini/api/owner-expenditure/property-expense/attachment',
      null,
      data,
      false,
      'multipart/form-data'
    ),
  deleteExpenditureAttachment: async attachmentId =>
    makeRequest(
      'DELETE',
      `singgahsini/api/owner-expenditure/property-expense/attachment/${attachmentId}`,
      null,
    ),
  postExpenditure: async data =>
    makeRequest(
      'POST',
      'singgahsini/api/owner-expenditure/property-expense',
      null,
      data
    ),
  putExpenditure: async (propertyId, data) =>
    makeRequest(
      'PUT',
      `singgahsini/api/owner-expenditure/property-expense/${propertyId}`,
      null,
      data
    ),
  getPropertyExpenses: async params =>
    makeRequest(
      'GET',
      `singgahsini/api/owner-expenditure/property-expense`,
      params
    ),
  getDetailPropertyExpenses: async propertyId =>
    makeRequest(
      'GET',
      `singgahsini/api/owner-expenditure/property-expense/${propertyId}/edit`,
    ),
  postManagerApprove: async transactionId =>
    makeRequest(
      'POST',
      `singgahsini/api/owner-expenditure/property-expense/${transactionId}/approved/manager`
    ),
  putFinanceApprove: async transactionId =>
    makeRequest(
      'PUT',
      `singgahsini/api/owner-expenditure/property-expense/${transactionId}/approved/finance`
    ),
  deleteExpenditure: async transactionId =>
    makeRequest(
      'DELETE',
      `singgahsini/api/owner-expenditure/property-expense/${transactionId}`
    ),
  getExpenditureFilterVendor: async () =>
    makeRequest(
      'GET',
      'singgahsini/api/owner-expenditure/property-expense/filter-attribute'
    )
};

export { endpoints };